<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Hàng không kinh doanh</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <!-- Start: Belong to -->
      <div class="">
        <div class="font-weight-bold mb-2">Thuộc về</div>
        <v-radio-group class="mt-0" dense hide-details>
          <v-radio label="ThinkPro" :value="1"></v-radio>
          <v-radio label="Khách" :value="2"></v-radio>
        </v-radio-group>
      </div>
      <!-- End: Belong to -->
      <!-- Start: Type -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Loại</div>
        <!--          <v-radio label="Tất cả" value="all"></v-radio>-->
        <v-checkbox
          v-for="item in internalTypeList"
          :key="item.value"
          :label="item.text"
          :value="item.value"
          class="mt-1"
          dense
          hide-details
        ></v-checkbox>
      </div>
      <!-- End: Type -->
      <!-- Start: Brand -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Hãng</div>
        <v-autocomplete
          class="tp-filter-autocomplete"
          :items="brands"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn hãng"
        ></v-autocomplete>
      </div>
      <!-- End: Brand -->
      <!-- Start: Product type -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Loại sản phẩm</div>
        <v-autocomplete
          class="tp-filter-autocomplete"
          :items="productTypes"
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          chips
          single-line
          small-chips
          multiple
          placeholder="Chọn loại sản phẩm"
        ></v-autocomplete>
      </div>
      <!-- End: Product type -->
      <!-- Start: Product source -->
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nguồn hàng</div>
        <v-radio-group class="mt-0" dense hide-details;>
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Chính hãng" :value="5"></v-radio>
          <v-radio label="Nhập khẩu" :value="6"></v-radio>
        </v-radio-group>
      </div>
      <!-- Start: Product source -->
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerTypeList: [
        { value: 1, text: "Bảo hành" },
        { value: 2, text: "Sửa chữa" }
      ],
      internalTypeList: [
        { value: 1, text: "Sửa chữa nội bộ" },
        { value: 2, text: "Bảo hành nội bộ" },
        { value: 3, text: "Chờ lắp ráp" },
        { value: 4, text: "Media mượn" },
        { value: 5, text: "Cấp cho nhân viên" },
        { value: 6, text: "Hàng trả chờ nhập" },
        { value: 7, text: "Hàng chờ lắp ráp" },
        { value: 8, text: "Hàng trưng bày" }
      ]
    };
  },
  computed: {
    brands() {
      return this.$store.getters["BRAND/allBrands"];
    },
    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    }
  },
  created() {
    if (this.brands.length === 0) {
      this.$store.dispatch("BRAND/getAllBrands");
    }
    if (this.productTypes.length === 0) {
      this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
