<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="false"
    loading-text="Đang tải dữ liệu"
    :items="[]"
    item-key="id"
  >
    <template v-slot:[`item.SKU`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.SKU)"
          >
            {{ item.SKU }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.SKU)"
          >
            {{ "983453253" }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Tên sản phẩm",
          align: "start",
          sortable: false,
          value: "product_name"
        },
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "SKU"
        },
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "soluong_serials"
        },
        {
          text: "Mô tả",
          align: "start",
          sortable: false,
          value: "date_ton_lau"
        },
        {
          text: "Nhà cung cấp",
          align: "center",
          sortable: false,
          value: "so_luong_dang_ve"
        },
        {
          text: "Phiếu nhập",
          align: "center",
          sortable: false,
          value: "nhap_gan_nhat"
        },
        {
          text: "Giá nhập",
          align: "center",
          sortable: false,
          value: "ban_gan_nhat"
        },
        {
          text: "Phân loại",
          align: "center",
          sortable: false,
          value: "capnhat_giangay"
        },
        {
          text: "Nơi tiếp nhận",
          align: "center",
          sortable: false,
          value: "gia_von_trung_binh"
        },
        {
          text: "Người tiếp nhận",
          align: "center",
          sortable: false,
          value: "price"
        },
        {
          text: "Phát sinh",
          align: "center",
          sortable: false,
          value: "price"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "price"
        }
      ]
    };
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
