<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :brands-filter="brandsFilter"
        :front-display-filter="frontDisplayFilter"
        :product-source-filter="productSourceFilter"
        :product-status-filter="productStatusFilter"
        :product-types-filter="productTypesFilter"
        :sale-status-filter="saleStatusFilter"
        :store-status-filter="storeStatusFilter"
        @updateBrandsFilter="updateBrandsFilter($event)"
        @updateFrontDisplayFilter="updateFrontDisplayFilter($event)"
        @updateProductSourceFilter="updateProductSourceFilter($event)"
        @updateProductStatusFilter="updateProductStatusFilter($event)"
        @updateProductTypesFilter="updateProductTypesFilter($event)"
        @updateSaleStatusFilter="updateSaleStatusFilter($event)"
        @updateStoreStatusFilter="updateStoreStatusFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        :search-type="searchType"
        :search-options="searchOptions"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedProductOptions="selectedProductOptions = $event"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      brandsFilter: null,
      currentPage: 1,
      itemPerPage: 50,
      productSourceFilter: null,
      productStatusFilter: [],
      productTypesFilter: null,
      searchKey: null,
      searchType: 0,
      searchOptions: [
        { type: "searchProduct", label: "Theo mã, tên sản phẩm, serial" }
      ]
    };
  },
  watch: {
    "$route.query"(val) {
      this.getProductOptions(val);
    }
  },
  async created() {
    const route = this.$route;

    await this.getProductOptions(route.query);
  },
  methods: {
    async getProductOptions(query) {
      if (query.page) {
        // Set filtered brands
        this.brandsFilter =
          typeof query.brand_id === "string"
            ? [parseInt(query.brand_id)]
            : typeof query.brand_id === "object"
            ? query.brand_id.map(item => parseInt(item))
            : null;
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set front display
        this.frontDisplayFilter = query.fr_display
          ? parseInt(query.fr_display)
          : "all";
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set filtered product source
        this.productSourceFilter = query.pr_src
          ? parseInt(query.pr_src)
          : "all";
        // Set filtered product status
        this.productStatusFilter =
          typeof query.pr_status === "string"
            ? [parseInt(query.pr_status)]
            : typeof query.pr_status === "object"
            ? query.pr_status.map(item => parseInt(item))
            : [];
        // Set filtered product types
        this.productTypesFilter =
          typeof query.category_id === "string"
            ? [parseInt(query.category_id)]
            : typeof query.category_id === "object"
            ? query.category_id.map(item => parseInt(item))
            : null;
        // Set filtered sale status
        this.saleStatusFilter = parseInt(query.ttkd);
        // Set filtered store status
        this.storeStatusFilter = query.store_status
          ? parseInt(query.store_status)
          : "all";
        // Set search key
        this.searchKey = query.search || null;
        // Set search type
        this.searchType = query.search ? parseInt(query.search_type) : 0;
        // Get products
        await this.$store.dispatch("PRODUCT_OPTION/getProductOptions", {
          filter: {
            category_id: this.productTypesFilter,
            brand_id: this.brandsFilter,
            nguon_hang:
              this.productSourceFilter && this.productSourceFilter !== "all"
                ? [this.productSourceFilter]
                : null,
            tinh_trang: this.productStatusFilter,
            tinh_trang_kho:
              this.storeStatusFilter && this.storeStatusFilter !== "all"
                ? this.storeStatusFilter
                : null,
            front_display:
              this.frontDisplayFilter !== "all" &&
              this.frontDisplayFilter !== null
                ? [this.frontDisplayFilter]
                : null,
            ttkd: [this.saleStatusFilter]
          },
          searchProduct:
            this.searchOptions[this.searchType].type === "searchProduct"
              ? this.searchKey
              : null,
          searchOption:
            this.searchOptions[this.searchType].type === "searchOption"
              ? this.searchKey
              : null,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    async pushRouter() {
      await this.$router.push({
        name: "not-sold-warehouse_all-serials",
        query: {
          category_id:
            this.productTypesFilter && this.productTypesFilter.length > 0
              ? this.productTypesFilter
              : undefined,
          brand_id:
            this.brandsFilter && this.brandsFilter.length > 0
              ? this.brandsFilter
              : undefined,
          fr_display:
            this.frontDisplayFilter !== null &&
            this.frontDisplayFilter !== "all"
              ? this.frontDisplayFilter
              : undefined,
          pr_src:
            this.productSourceFilter && this.productSourceFilter !== "all"
              ? this.productSourceFilter
              : undefined,
          pr_status:
            this.productStatusFilter && this.productStatusFilter.length > 0
              ? this.productStatusFilter
              : undefined,
          store_status:
            this.storeStatusFilter && this.storeStatusFilter !== "all"
              ? this.storeStatusFilter
              : undefined,
          ttkd: this.saleStatusFilter,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          search_type:
            this.searchKey && this.searchKey.length > 0
              ? this.searchType
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateBrandsFilter(brandIds) {
      this.brandsFilter = brandIds;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateProductSourceFilter(val) {
      this.productSourceFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateProductTypesFilter(cateIDs) {
      this.productTypesFilter = cateIDs;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      if (val.option) {
        this.searchType = this.getIndexOfSearchOption(val.option);
      }
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
