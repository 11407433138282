var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":[],"item-key":"id"},scopedSlots:_vm._u([{key:"item.SKU",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.SKU)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.SKU)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.serial_number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.SKU)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s("983453253")+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }